@import '../_constants.module.scss';
@import '../type/type.scss';
@import '../common';

.main {
  @include main;
  min-height: 60vh;
}

.hero {
  @include maxWidthContainer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vh;

  h1 {
    @include abc_bold_140_89;
    padding: 0 $phone-margin;
    margin: auto 0 0.5em;

    @include desktop-and-tablet {
      padding: 0 $desktop-margin;
    }
  }
}
